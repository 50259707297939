import React from 'react'

import SecondFold from './../home/SecondFold'

import SubPage from '../subPage'
import { Icon } from './style'
import { Flex, Box } from '@rebass/grid/emotion'
import { Heading, Text } from '@rebass/emotion'
import { css } from '@emotion/react'
import styled from "@emotion/styled";

const c = css`
    font-weight: 400;
    line-height: 2em;
    hyphens: auto;
    width: 90%;
    max-width: 70rem;
    margin: 2.5rem auto 0 auto;

    em {
        font-style: normal;
        font-weight: 400;
        hyphens: manual;
        color: #4975ba;
        border-bottom: 1px solid #4975ba;
    }

`

const Content3 = css`
    hyphens: auto;
`

const BoxUl = styled(Box)`
    background-color: #f6f9fc;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 6px,
        rgba(0, 0, 0, 0.08) 2px 2px 6px;
    border-radius: 6px;
    padding: 20px 25px 20px 48px;

    @media screen and (min-width: 1280px) {
        align-self: flex-start;
        padding: 3rem 5rem;
    }

    > li {
        padding: 0;
        color: #32335c;
        margin-left: 0.75em;
        padding-left: 0.5em;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 1.8em;

        @media screen and (min-width: 1280px) {
            font-size: 2.1rem;
        }
    }
`

export default () =>
    <SubPage>
        <Box mx={["auto"]} css={{ maxWidth: 1250, width: '90%' }} mb={[50, 100]}>
            <Flex justifyContent="center">
                <Box width={1}>
                    <Text color="#6f7c82" fontSize={[18, 18, 18, 20]} fontWeight={600} textAlign="center">Unsere Balkon-Varianten</Text>
                    <Heading color="#292e31" fontSize={[30, 42, 46, 60]} fontWeight={600} textAlign="center">Leistungen</Heading>
                    <Text css={c} color="#32335c" fontWeight={400} fontSize={[16, 16, 18, 22]} lineHeight={40} textAlign="center">
                        Alles aus einer Hand. Planung, Produktion und Montage
                    </Text>
                </Box>
            </Flex>
            <Flex my={[10, 20]} flexDirection="column">
                <Box my={[20, 40]}>
                    <Svg1 />
                    <Heading mb={[10, 30]} mt={[10, 20]} fontWeight={600} textAlign="center" fontSize={30} color="#4975ba">Planung</Heading>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                        Wir planen und entwickeln Ihre Balkonanlage individuell für Ihr Objekt.
                    </Text>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                    Nach einer ausführlichen Beratung erstellen wir Ihnen ein kostenloses, auf Ihr Bauvorhaben zugeschnittenes Preisangebot. Die Einholung der Baugenehmigung durch unsere erfahrenen Architekten sowie aller weiteren behördlichen Genehmigungen gehört ebenso zu unserem Leistungsangebot wie die Erstellung der prüffähigen Statik.
                    </Text>

                    <BoxUl as="ul">
                        <li>Beratung vor Ort</li>
                        <li>Erstellung eines individuellen Angebots für Ihr Objekt</li>
                        <li>Objektbezogenes Planen und Bauen</li>
                        <li>Erstellung einer Statik</li>
                    </BoxUl>
                </Box>
                <Box my={[20, 40]}>
                    <Svg2 />

                    <Heading mb={[10, 30]} mt={[10, 20]} fontWeight={600} textAlign="center" fontSize={30} color="#4975ba">Produktion</Heading>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                        In unserer werkseigenen Produktion fertigen wir alle benötigten Komponenten für Ihre Balkonanlage
                    </Text>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                        Unser Produktionsverfahren ist nach DIN EN 1090 zertifiziert, Diese europäische Norm gilt für tragende Bauteile aus Stahl. Dies ständige Überwachung, sondern auch eine umfangreiche Qualitätskontrolle während und nach der Produktion.
                    </Text>

                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                    Alle Stahlbauteile sind feuerverzinkt nach DIN EN ISO 1461. Die Pulverbeschichtung in allen RAL- und DB-Farbtönen erfolgt nach DIN 55633.
                    </Text>
                    <BoxUl as="ul">
                        <li>Werkseigene Produktion</li>
                        <li>Verzinkung nach DIN EN ISO 1461</li>
                        <li>wahlweise Pulverbeschichtung/Duplexbeschichtung</li>
                    </BoxUl>

                </Box>
                <Box my={[20, 40]}>
                    <Svg3 />

                    <Heading mb={[10, 30]} mt={[10, 20]} fontWeight={600} textAlign="center" fontSize={30} color="#4975ba">Montage</Heading>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                        Die Montage unserer Balkonanlagen erfolgt bundesweit durch hochqualifizierte Monteure mit langjähriger Erfahrung in unserem Unternehmen.
                    </Text>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                        Unsere Balkone werden OHNE Gerüst und OHNE teuren Kran montiert. Dies ermöglicht eine problemlose Montage der Balkonanlagen in schwer zugänglichen Bereichen, z.B.: zweiter oder gar dritter Hinterhof in einem dicht besiedelten Stadtgebiet. Die Montage ohne Kran und ohne Gerüst ist auch eine sehr wirtschaftliche Variante, vor allem dort, wo durch U-Bahn, Straßenbahn oder eine stark befahrene Straße die Aufstellung eines Krans zum Hindernis werden kann.
                    </Text>
                    <Text css={Content3} mb={20} color="#32335c" fontWeight={400} fontSize={20} lineHeight={1.7}>
                    Die Balkonanlagen werden in Einzelteilen angeliefert und auf der Baustelle montiert. So erreichen wir auch die entlegensten Hinterhöfe durch einen schmalen Kellergang.
                    </Text>
                    <BoxUl as="ul">
                        <li>Abbruch bestehender Balkone</li>
                        <li>Erstellen der Fundamente</li>
                        <li>Anlieferung aller Komponenten durch hauseigene Spedition</li>
                        <li>Montage vor Ort durch unser Fachpersonal</li>
                    </BoxUl>
                </Box>

            </Flex>

        </Box>
        <SecondFold />
    </SubPage>




const Svg1 = () =>
    <Icon>
        <span>
            <svg
                version="1.1"
                viewBox="0 0 512.001 512.001"
            // style="enable-background:new 0 0 512.001 512.001;"
            >
                <path
                    d="M503.28,44.897c4.817,0,8.721-3.905,8.721-8.721V8.721c0-4.816-3.903-8.721-8.721-8.721H108.633
                    c-4.816,0-8.721,3.905-8.721,8.721v394.645c0,4.816,3.905,8.721,8.721,8.721h394.645c4.817,0,8.721-3.905,8.721-8.721V65.245
                    c0-4.816-3.903-8.721-8.721-8.721c-4.817,0-8.721,3.905-8.721,8.721v149.416h-34.634c-4.817,0-8.721,3.905-8.721,8.721
                    s3.903,8.721,8.721,8.721h34.634v162.543H245.393v-42.197c0-4.816-3.905-8.721-8.721-8.721c-4.816,0-8.721,3.905-8.721,8.721
                    v42.197H117.354V17.442h110.598v272.218c0,4.816,3.905,8.721,8.721,8.721c4.816,0,8.721-3.905,8.721-8.721v-57.557h155.229
                    c4.817,0,8.721-3.905,8.721-8.721s-3.903-8.721-8.721-8.721H245.393V17.442h249.166v18.734
                    C494.559,40.992,498.462,44.897,503.28,44.897z"
                />
                <path
                    d="M73.679,50.331L44.902,4.112C43.31,1.555,40.51,0,37.499,0c-3.012,0-5.811,1.555-7.403,4.112L1.318,50.331
                    c-1.674,2.689-1.759,6.074-0.221,8.843s4.457,4.487,7.624,4.487h20.057v36.468c0,4.816,3.905,8.721,8.721,8.721
                    c4.816,0,8.721-3.905,8.721-8.721V63.661h20.057c3.167,0,6.086-1.717,7.624-4.487S75.354,53.019,73.679,50.331z M24.424,46.219
                    l13.074-20.998l13.074,20.998H24.424z"
                />
                <path
                    d="M73.9,352.914c-1.538-2.769-4.457-4.486-7.624-4.486H46.219V123.384c0-4.816-3.905-8.721-8.721-8.721
                    c-4.816,0-8.721,3.905-8.721,8.721v225.043H8.721c-3.167,0-6.086,1.717-7.624,4.487c-1.538,2.77-1.453,6.155,0.221,8.843
                    l28.777,46.219c1.592,2.557,4.391,4.112,7.403,4.112c3.013,0,5.812-1.555,7.403-4.112l28.777-46.219
                    C75.354,359.067,75.439,355.683,73.9,352.914z M37.499,386.868L24.424,365.87h26.148L37.499,386.868z"
                />
                <path
                    d="M507.889,467.099l-46.219-28.777c-2.689-1.674-6.074-1.758-8.843-0.221c-2.769,1.538-4.486,4.457-4.486,7.624v20.057
                    H163.573v-20.057c0-3.167-1.717-6.086-4.486-7.624c-2.769-1.537-6.153-1.453-8.844,0.221l-46.219,28.777
                    c-2.557,1.592-4.112,4.391-4.112,7.403s1.555,5.811,4.112,7.403l46.219,28.777c1.408,0.877,3.008,1.317,4.609,1.317
                    c1.457,0,2.915-0.364,4.234-1.096c2.77-1.538,4.487-4.457,4.487-7.624v-20.057H448.34v20.057c0,3.167,1.719,6.086,4.486,7.624
                    c1.32,0.733,2.778,1.096,4.235,1.096c1.601,0,3.201-0.441,4.609-1.317l46.219-28.777c2.557-1.592,4.112-4.391,4.112-7.403
                    C512.001,471.489,510.446,468.691,507.889,467.099z M146.131,487.576l-20.998-13.074l20.998-13.074V487.576z M465.782,487.576
                    v-26.148l21,13.074L465.782,487.576z"
                />
            </svg>
        </span>
    </Icon>

const Svg2 = () =>
    <Icon>
        <span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 57.093 57.093"
            >
                <path d="M51.13 19.307H34.38V11.89H22.712v7.417H5.962c-.874 0-1.583.71-1.583 1.583s.7 1.583 1.58 1.583h16.75v7.417h3.9v2.404c0 .856.56 1.575 1.33 1.826.04.8.23 1.335.57 1.708.6.656 1.55.654 2.76.646.35 0 .73-.003 1.15.01.78.024 1.19.162 1.36.254-.38.308-1.95.822-5.25.492-1.78-.18-4.23-.297-5.03.78-.22.292-.41.795-.1 1.54.1.245.25.432.42.592-.58.17-1.147.393-1.68.678l.09 2.547c-.388.302-.74.647-1.06 1.037l-2.55-.168c-.16.28-.31.567-.44.87-.13.3-.238.606-.333.915l1.876 1.74c-.06.5-.07 1-.02 1.49l-1.92 1.68c.172.63.413 1.23.72 1.81l2.55-.09c.3.39.65.75 1.036 1.06l-.168 2.56c.28.16.57.31.87.44.3.13.607.24.914.34l1.74-1.87c.5.06.996.07 1.484.02l1.68 1.92c.627-.17 1.23-.41 1.807-.72l-.09-2.55c.39-.3.747-.65 1.06-1.04l2.55.17c.16-.28.31-.56.44-.87.13-.3.24-.6.33-.91l-1.87-1.74c.07-.5.07-.99.03-1.48l1.92-1.68c-.17-.63-.41-1.232-.72-1.805l-2.55.09c-.3-.39-.65-.74-1.033-1.06l.17-2.55c-.117-.063-.247-.12-.37-.182-.69-.81-2-.842-3.273-.87-1.18-.03-2.4-.054-2.67-.714-.16-.38-.06-.52-.025-.56.167-.23.9-.715 4.2-.38 3.12.31 5.54-.084 6.174-1.01.19-.277.22-.6.074-.884-.34-.658-1.44-.828-2.31-.855-.43-.01-.83-.01-1.19-.01-.996.01-1.72.01-2.024-.32-.167-.18-.26-.51-.3-.98.862-.19 1.51-.96 1.51-1.88V29.9h3.905v-7.42h16.75c.87 0 1.58-.71 1.58-1.584s-.71-1.582-1.584-1.582zM30.365 50.34c-.964 2.236-3.556 3.266-5.79 2.303-2.236-.964-3.266-3.556-2.304-5.79.97-2.237 3.56-3.267 5.8-2.303 2.24.963 3.27 3.555 2.3 5.79zm-3.355-9.458c.4.01.805.02 1.176.05l-1.03 1.11c-.497-.06-.995-.07-1.484-.022l-1.384-1.576c.74.386 1.77.417 2.722.438z" />
                <path d="M21.59 16.203h.33v-3.375h-.33c-.934 0-1.69.755-1.69 1.688 0 .933.756 1.687 1.69 1.687zm13.31-3.375v3.375h.332c.934 0 1.688-.755 1.688-1.688 0-.933-.755-1.688-1.688-1.688H34.9zm-5.504-1.316l.275-.003c-.02-1.67.37-2.87 1.18-3.67 1.54-1.52 4.52-1.45 7.41-1.38 3.01.07 6.12.15 7.79-1.61.94-.99 1.33-2.41 1.18-4.36l-1 .08c.12 1.65-.17 2.82-.91 3.59-.89.94-2.39 1.23-4.13 1.3.39-.21.75-.46 1.06-.79.93-1.01 1.31-2.54 1.15-4.69l-1 .07c.14 1.85-.15 3.14-.88 3.93-1.1 1.19-3.15 1.21-5.32 1.22-2.35.01-4.79.03-6.33 1.6-1.01 1.02-1.49 2.57-1.46 4.72l1-.02v-.02z" />
                <circle cx="26.319" cy="48.596" r="2.203" />
                <path d="M44.22 46.697l-.606-.215.275-.586.63-1.342c-.24-.294-.51-.564-.8-.803l-1.35.65-.58.28-.22-.6-.5-1.4-.57-.03-.57.03-.5 1.41-.22.61-.58-.28-1.34-.632c-.3.236-.57.507-.81.8l.64 1.347.27.58-.608.213-1.406.5-.03.562.03.566 1.406.5.61.216-.272.588-.64 1.34c.234.297.505.56.8.8l1.345-.64.58-.273.21.61.5 1.403.565.03.565-.03.5-1.404.214-.61.584.276 1.347.64c.3-.24.567-.51.807-.807l-.65-1.35-.28-.584.606-.216 1.404-.5.03-.566-.03-.57-1.407-.5zm-3.702 2.168c-.61 0-1.103-.492-1.103-1.102s.493-1.102 1.103-1.102c.608 0 1.102.5 1.102 1.11s-.495 1.1-1.102 1.1z" />
            </svg>
        </span>
    </Icon>

const Svg3 = () =>
    <Icon>
        <span>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 508 508"
            >
                <path d="M204.8 139.2h-21L131 229.4 78.4 139h-21C25.7 139.3 0 165 0 196.6v114.7c0 29 21.4 52.8 49.2 56.8v140H213V368c27.8-4 49.2-28 49.2-56.7V196.5c0-31.6-25.7-57.3-57.4-57.3zM49 351.4c-18.5-3.7-32.6-20.3-32.6-40V196.6c0-8 2.4-15.5 6.4-21.8L49 247v104.4zm147.7-107v247H139v-90h-16.4v90h-57v-247l-30-82.4c13.5-8.8 33.4-6.4 33.4-6.4L131 262l62.2-106.4s24-2 33.4 6.4l-30 82.3zm49 66.8c0 20-14 36.4-32.7 40.2v-104l26.3-72.5c4 6 6.5 13 6.5 21v115zM131 0C95 0 65.6 29.4 65.6 65.5 65.6 101.7 95 131 131 131c36.2 0 65.6-29.3 65.6-65.4 0-36-29.4-65.5-65.5-65.5zm0 114.8c-27 0-49-22-49-49.3 0-27 22-49 49-49 27.2 0 49.3 22 49.3 49 0 27.2-22 49.3-49.2 49.3zm147.6 8v16.4c63.3 0 114.7 51.6 114.7 114.8 0 63.2-51.5 114.6-114.7 114.6V385c72.3 0 131-58.8 131-131s-58.7-131.2-131-131.2z" />
                <path d="M508 301.4v-94.7l-41.4-10.4c-3.6-12-8.4-23.4-14.2-34.4l22-37-67-67-36.6 22c-11-6-22.5-11-34.4-14L326 24h-94.7l-11.5 46 16 4 8.2-33h69l9.7 38c22.4 5.8 40 15.3 48.3 20.2L404.8 79l49 48.8-20.4 33.8c8.7 13.5 19 40 20 48.3l38.2 9v69l-38.2 9.4c-3.3 12.3-10 32.5-20 48.3l20.3 34-49 49-33.7-20.5c-20.2 12-34.5 16-48.3 20L313 467h-69l-8.4-33.6-16 4 11.6 46H326l10.4-41.4c12-3.6 23.4-8.4 34.4-14.2l36.6 22 67-67-22-36.6c5.8-11 10.6-22.5 14.2-34.4l41.4-10.4z" />
            </svg>{' '}
        </span>
    </Icon>